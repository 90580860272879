import Cookies from "js-cookie";
import cookies from "next-cookies";
import redirect from "../lib/redirect";
import gql from "graphql-tag";
import { copyUrlArgs } from "./utils";
import * as Sentry from "@sentry/browser";

export const MEMBER = gql`
  query Member {
    member {
      pseudo
    }
  }
`;

export const checkLoggedIn = ctx => {
  const { token } = cookies(ctx);
  return token ? true : false;
};

export const checkMember = async (ctx, origin) => {
  const member = await ctx.apolloClient
    .query({
      query: MEMBER,
      fetchPolicy: "no-cache"
    })
    .then(result => {
      if (result && result.data) {
        if (result.data.member && result.data.member.pseudo) {
          return result.data.member.pseudo;
        } else {
          let url = "/pseudo";
          if (ctx && ctx.query) {
            copyUrlArgs(url, ctx.query);
          }
          redirect(ctx, url);
        }
      } else {
        Sentry.captureMessage("CheckMember > query > no result/data");
        // logout(ctx.apolloClient);
      }
    });
  // .catch(e => {
  // logout(ctx.apolloClient);
  // alert("checkmember erro") //SENTRY
  // });
  return member;
};

const handleToken = (apolloClient, token) => {
  // Store the token in cookie
  Cookies.set("token", token, {
    expires: 365
    // domain: window.location.hostname,
    // path: "/",
    // secure: true,
    // httpOnly: true
  });
  // Force a reload of all the current queries now that the user is
  // logged in
  apolloClient.cache.reset().then(() => {});
};

export const logout = apolloClient => {
  Cookies.remove("token");
  apolloClient.cache.reset().then(() => {
    redirect({}, "/");
  });
};

const TOKEN_AUTH = gql`
  mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(input: { username: $username, password: $password }) {
      token
    }
  }
`;

export const tokenAuth = async (apolloClient, username, password) => {
  const res = await apolloClient
    .mutate({
      mutation: TOKEN_AUTH,
      variables: { username: username, password: password }
    })
    .then(
      result => {
        if (result.data.tokenAuth.token) {
          handleToken(apolloClient, result.data.tokenAuth.token);
          return true;
        } else {
          return false;
        }
      },
      error => {
        // !!! important
        // called when code is wrong
        // console.log(error);
      }
    );
  return res;
};

const PHONE_NUMBER_REGISTER = gql`
  mutation PhoneNumberRegister($number: String!) {
    phoneNumberRegister(number: $number) {
      status
      error
    }
  }
`;
const PHONE_NUMBER_LOGIN = gql`
  mutation PhoneNumberLogin($number: String!) {
    phoneNumberLogin(number: $number) {
      status
      error
    }
  }
`;

export const phoneNumberRegister = async (apolloClient, number) => {
  const res = await apolloClient
    .mutate({
      mutation: PHONE_NUMBER_REGISTER,
      variables: { number: number }
    })
    .then(result => {
      return result.data.phoneNumberRegister;
    });
  return res;
};

export const phoneNumberLogin = async (apolloClient, number) => {
  const res = await apolloClient
    .mutate({
      mutation: PHONE_NUMBER_LOGIN,
      variables: { number: number }
    })
    .then(result => {
      return result.data.phoneNumberLogin;
    });
  return res;
};

const EMAIL_REGISTER = gql`
  mutation EmailRegister($email: String!) {
    emailRegister(email: $email) {
      status
      error
    }
  }
`;
const EMAIL_LOGIN = gql`
  mutation EmailLogin($email: String!) {
    emailLogin(email: $email) {
      status
      error
    }
  }
`;

export const emailRegister = async (apolloClient, email) => {
  const res = await apolloClient
    .mutate({
      mutation: EMAIL_REGISTER,
      variables: { email: email }
    })
    .then(result => {
      return result.data.emailRegister;
    });
  return res;
};

export const emailLogin = async (apolloClient, email) => {
  const res = await apolloClient
    .mutate({
      mutation: EMAIL_LOGIN,
      variables: { email: email }
    })
    .then(result => {
      return result.data.emailLogin;
    });
  return res;
};
